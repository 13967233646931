import classNames from 'classnames';
import React, { useEffect } from 'react';
import { PasswordHint, PasswordStrengthLabel, PasswordStrengthList, PasswordStrengthWrapper } from './password-strength.styles';
import { safeCallback } from '@keplerco/core';

export function checkRules(password: string): { name: string; value: boolean }[] {
  const correctLength = password.length >= 8;

  const hasNumber = /[0-9]/gi.test(password);

  const hasUppercase = /[A-Z]/g.test(password);

  const hasSpecial = /[^a-z0-9]/gi.test(password);

  const hasLowercase = /[a-z]/g.test(password);

  return [
    { name: 'correctLength', value: correctLength },
    { name: 'hasNumber', value: hasNumber },
    { name: 'hasUppercase', value: hasUppercase },
    { name: 'hasSpecial', value: hasSpecial },
    { name: 'hasLowercase', value: hasLowercase },
  ].sort((x, y) => {
    // make sure that the fields are in the order
    // of false items last and tru items first
    // to give the user the impression of progress
    // as the pass word gets stronger
    return x.value === y.value ? 0 : x.value ? -1 : 1;
  });
}

function suggestion(key: string) {
  if (key === 'correctLength') {
    return 'Your password should be 8 characters long';
  } else if (key === 'hasNumber') {
    return 'Try adding a number';
  } else if (key === 'hasUppercase') {
    return 'Try adding an uppercase letter';
  } else if (key === 'hasSpecial') {
    return 'Try adding a special character';
  } else if (key === 'hasLowercase') {
    return 'Try adding a lowercase letter';
  } else if (key === 'noBlank') {
    return 'Please enter a password';
  }
}

export function PasswordStrength(props: { password: string; onPercentage?: (percentageCorrect: number, message: string) => void }): JSX.Element {
  const { password = '' } = props;

  const ruled = checkRules(password);

  const countCorrect = ruled.filter(i => i.value === true).length;

  const percent = (countCorrect / ruled.length) * 100;

  const firstSuggestion = ruled.find(r => !r.value);

  useEffect(() => {
    safeCallback(props.onPercentage, percent, suggestion(firstSuggestion?.name ?? ''));
  }, [percent, firstSuggestion]);

  return (
    <PasswordStrengthWrapper>
      <PasswordStrengthLabel>Password strength:</PasswordStrengthLabel>

      <PasswordStrengthList className={classNames({ red: percent > 0 && percent <= 25, yellow: percent > 25 && percent < 100, green: percent === 100 })}>
        {ruled.map(rule => {
          return <li data-suggestion={suggestion(rule.name)} key={rule.name} className={rule.value ? 'filled' : 'empty'}></li>;
        })}
      </PasswordStrengthList>

      {!!firstSuggestion && !!password.length && <PasswordHint>{suggestion(firstSuggestion.name)}</PasswordHint>}
    </PasswordStrengthWrapper>
  );
}
