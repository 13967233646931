import { Anchor, Button, DropdownSearch, DropdownSelect, DropdownSelectItem, FieldController, RequiredValidator, TextArea, TextField, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import { UniqueEntity } from '../../../../../models';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ImportRoleLayout } from './import-role/import-role.layout';
import { SkeletonLoader } from '../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { useKeplerNavigate } from '../../../../../navigation/guards/use-kepler-navigate';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { IDetailsStepLayoutProps, RegenerateOption } from './details-step.models';
import { CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';
import { AIGenerateRoleRequest } from '../../../../../overmind/actions';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { EntityType, OrganizationLevelType } from '../../../../../enums';
import { StepSkeleton } from '../../../../../components/general/stepper/step.skeleton';

export function DetailsStepLayout({ path, seniorities, roleSlug, selectedRole, setSelectedRole, completeStep }: IDetailsStepLayoutProps): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<UniqueEntity[]>([]);
  const [regenerating, setRegenerating] = useState<boolean>(false);
  const [instructionType, setInstructionType] = useState<string>('');

  const [skills, setSkills] = useState<CompanySkillListItemResponse[]>([]);

  const { getValues, setValue, control, trigger, reset, handleSubmit } = useForm<any>({
    mode: 'onBlur',
    defaultValues: {
      name: selectedRole?.name,
      description: selectedRole?.description,
      seniorityLevel: selectedRole?.seniorityLevel,
    },
  });

  const isMobile = useMatchScreenWidth('mobile');

  const regenerateOptions: DropdownSelectItem[] = [
    { value: RegenerateOption.Expand, onClick: () => setInstructionType(RegenerateOption.Expand) },
    { value: RegenerateOption.Shorten, onClick: () => setInstructionType(RegenerateOption.Shorten) },
    { value: RegenerateOption.Simplify, onClick: () => setInstructionType(RegenerateOption.Simplify) },
    { value: RegenerateOption.Improve, onClick: () => setInstructionType(RegenerateOption.Improve) },
  ];

  useEffect(() => {
    async function getData() {
      const countriesResponse = await actions.getCountries();
      setCountries(countriesResponse?.sort((a, b) => a.name.localeCompare(b.name)) ?? []);
    }

    if (path === PagePath.roleSkillManagementWizardKeplerAI) getData();
  }, [path]);

  useEffect(() => {
    reset({
      name: selectedRole?.name,
      description: selectedRole?.description,
      seniorityLevel: selectedRole?.seniorityLevel?.toString(),
    });
  }, [selectedRole, seniorities]);

  useEffect(() => {
    const values = getValues();
    async function regenerateDescription() {
      if (instructionType) {
        const isValid = await trigger(['seniorityLevel']);
        if (!isValid) return;

        setRegenerating(true);

        const seniorityLevel = (seniorities ?? []).find(level => level.level === parseInt(values.seniorityLevel));
        const response = await actions.aiGenerateRoleDescription({
          name: values.name,
          description: values.description,
          seniorityLevel: seniorityLevel?.label ?? '',
          instructionType,
        });

        setValue('description', response?.description);

        setRegenerating(false);
      }
    }

    regenerateDescription();
  }, [instructionType]);

  async function handleSaveRole() {
    const isValid = await trigger(['name', 'seniorityLevel', 'description']);
    if (!isValid) return;

    const values = getValues();

    if (path === PagePath.roleSkillManagementWizardCustomRole) setSelectedRole({ ...values });

    setLoading(true);
    const request = {
      companySlug: companyVariables.slug!,
      name: values.name,
      description: values.description,
      seniorityLevel: parseInt(values.seniorityLevel),
    };

    const response = !!roleSlug ? await actions.updateCompanyRole({ ...request, entitySlug: roleSlug }) : await actions.createCompanyRole(request);
    const slug = response ?? roleSlug;

    if (!slug) {
      // TODO: error message?
      setLoading(false);
      return;
    }

    completeStep({
      roleSlug: slug,
      role: values, // TODO: we need 'seniorityLabel' in the parent
      skills,
    });
    setLoading(false);
  }

  if (loading) return <StepSkeleton />;

  return (
    <div style={{ marginTop: 30 }}>
      {path === PagePath.roleSkillManagementWizardKeplerAI && !selectedRole && (
        <EntitiesCardWidget title="Kepler AI" description="Fill in the fields below and we'll do the rest.">
          <div className="divider" />
          <form
            id="generate-role-details"
            onSubmit={handleSubmit(async values => {
              setLoading(true);
              const seniorityLevel = (seniorities ?? []).find(level => level.level === parseInt(values.seniorityLevel));
              const request: AIGenerateRoleRequest = {
                name: values.name,
                aboutTheRole: values.description,
                aboutTheIndustry: values.industry,
                seniorityLabel: seniorityLevel?.label ?? '',
                country: values.countryId,
              };
              const roleResponse = await actions.aiGenerateRole(request);

              if (!roleResponse) return setLoading(false);

              setSelectedRole({
                name: roleResponse.name,
                description: roleResponse.description,
                seniorityLevel: seniorities.find(seniority => seniority.label === roleResponse.seniorityLabel)?.level,
              });

              const skillsResponse = await actions.aiGenerateSkills(request);
              setSkills(skillsResponse ?? []);

              setLoading(false);
            })}
          >
            <FieldController
              name="name"
              control={control}
              rules={{
                required: 'Please enter a role name',
                pattern: {
                  value: /^[A-Za-z0-9\s-_]+$/,
                  message: 'Only letters, numbers, spaces, hyphens, and underscores are allowed (A-Z, a-z, 0-9, _, -)',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Role name"
                  responsive
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Please enter a role name',
                  }}
                />
              )}
            />

            <FieldController
              name="description"
              control={control}
              rules={{
                required: 'Please enter a description of the role',
                pattern: {
                  value: /^[A-Za-z0-9\s\p{P}]+$/u,
                  message: 'Only letters, numbers, spaces, and punctuation characters are allowed (A-Z, a-z, 0-9, _, -, &, !, ?, ...)',
                },
              }}
              render={({ field, fieldState }) => (
                <TextArea
                  {...field}
                  label="About the role"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Please enter a description of the role',
                  }}
                  responsive
                />
              )}
            />

            <FieldController
              name="industry"
              control={control}
              rules={{
                required: 'Please enter a description for the industry',
                pattern: {
                  value: /^[A-Za-z0-9\s\p{P}]+$/u,
                  message: 'Only letters, numbers, spaces, and punctuation characters are allowed (A-Z, a-z, 0-9, _, -, &, !, ?, ...)',
                },
              }}
              render={({ field, fieldState }) => (
                <TextArea
                  {...field}
                  label="About the industry"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Please enter a description for the industry',
                  }}
                  responsive
                />
              )}
            />

            <FieldController
              control={control}
              name="seniorityLevel"
              rules={new RequiredValidator()}
              render={({ field, fieldState }) => (
                <DropdownSelect
                  {...field}
                  label="Seniority level"
                  items={seniorities.map(seniority => ({
                    label: seniority.label,
                    value: seniority.level.toString(),
                  }))}
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please select a seniority level',
                  }}
                  responsive
                />
              )}
            />

            <FieldController
              control={control}
              name="countryId"
              rules={new RequiredValidator()}
              render={({ field, fieldState }) => (
                <DropdownSearch
                  {...field}
                  label="Country"
                  items={countries?.map(country => ({
                    value: country.name,
                    label: country.name,
                  }))}
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please select a country',
                  }}
                  responsive
                />
              )}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
              <Button type="button" arrow={false} filled>
                Generate
              </Button>
            </div>
          </form>
        </EntitiesCardWidget>
      )}

      {path === PagePath.roleSkillManagementWizardImportRole && !selectedRole && !roleSlug && (
        <ImportRoleLayout
          selectedRole={selectedRole}
          setSelectedRole={async role => {
            setSelectedRole(role);
            if (!role?.slug) return;

            const request: CompanyEntitySearchParams = {
              search: undefined,
              sortAscending: true,
              pageSize: 99999, // ridiculously high pageSize to fetch ALL skills
              page: 1,
              organizationLevel: OrganizationLevelType.Company,
              companySlug: companyVariables.slug,
              departmentSlug: undefined,
              teamSlug: undefined,
              learnerSlug: undefined,
              searchGlobally: true,
              providerName: 'ESCO',
              relatedType: EntityType.Role,
              roleSlug: role.slug,
            };
            const response = await actions.getCompanySkills(request);
            setSkills(response?.entities ?? []);
          }}
        />
      )}

      {(path === PagePath.roleSkillManagementWizardCustomRole || !!selectedRole || !!roleSlug) && (
        <EntitiesCardWidget title={path === PagePath.roleSkillManagementWizardKeplerAI ? 'Kepler AI role details' : 'Role details'} description={path === PagePath.roleSkillManagementWizardKeplerAI ? 'The role name and description have been generated for you.' : 'Fill in the fields below'}>
          <form id="save-role-details">
            <FieldController
              name="name"
              control={control}
              rules={{
                required: 'Please enter a role name',
                pattern: {
                  value: /^[A-Za-z0-9\s-_]+$/,
                  message: 'Only letters, numbers, spaces, hyphens, and underscores are allowed (A-Z, a-z, 0-9, _, -)',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Role name"
                  responsive
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please enter a role name',
                  }}
                />
              )}
            />

            <FieldController
              control={control}
              name="seniorityLevel"
              rules={{
                required: 'Please select a seniority level',
              }}
              render={({ field, fieldState }) => (
                <DropdownSelect
                  {...field}
                  label="Role seniority"
                  items={seniorities.map(seniority => ({
                    label: seniority.label,
                    value: seniority.level.toString(),
                  }))}
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please select a seniority level',
                  }}
                  responsive
                />
              )}
            />

            {regenerating ? (
              <SkeletonLoader height="100px" />
            ) : (
              <div style={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr auto', gap: 30 }}>
                <FieldController
                  name="description"
                  control={control}
                  rules={{
                    required: 'Please enter a role description',
                    pattern: {
                      value: /^[A-Za-z0-9\s\p{P}+]+$/u,
                      message: 'Only letters, numbers, spaces, and punctuation characters are allowed (A-Z, a-z, 0-9, _, -, &, !, ?, ...)',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextArea
                      {...field}
                      label="Role description"
                      validation={{
                        dirty: fieldState.isDirty || !!fieldState.error,
                        invalid: fieldState.invalid,
                        message: fieldState.error?.message ?? 'Please enter a role description',
                      }}
                      responsive
                    />
                  )}
                />

                <FieldController control={control} name="regenerate" rules={new RequiredValidator()} render={({ field }) => <DropdownSelect {...field} label="Regenerate" items={regenerateOptions} responsive />} />
              </div>
            )}
          </form>
        </EntitiesCardWidget>
      )}

      <footer className="card" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 30, background: 'var(--background)' }}>
        <Anchor arrow reverse onClick={() => keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizard.replace(':companySlug', companyVariables.slug!)}`)}>
          Cancel
        </Anchor>
        <Anchor arrow onClick={handleSaveRole}>
          Next
        </Anchor>
      </footer>
    </div>
  );
}
