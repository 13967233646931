import React, { Fragment, useEffect, useState } from 'react';
import { Anchor, Button, FocusPanelHeader, Modal } from '@keplerco/core';
import { IImportSkillLayoutProps } from './manage-role.models';
import { useAppActions, useAppState } from '../../../../overmind';
import { OrganizationLevelType, SortField } from '../../../../enums';
import { ConfirmationModalLayout } from '../../../../widgets/layouts';
import { NotificationPriority, NotificationType } from '../../../../notifications/notifications.models';
import { extractHighestOrganizationLevel } from '../../../../library/helpers/permissions/extract-highest-organization-level';
import { CompanyEntitySearchParams } from '../../../../models/overmind/search-params';
import { SearchList } from '../../../../components/lists/search-list';
import { ListItem } from '../../../../components/lists/list-item';
import { CompanySkillListItemResponse, CompanySkillListResponse } from '../../../../models/overmind/company-entity';

const pageSize = 5;

export function ImportSkillLayout({ supertitle, onClose }: IImportSkillLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);

  const [loading, setLoading] = useState<boolean>(true);
  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: true,
  });
  const [data, setData] = useState<CompanySkillListResponse>();
  const [skillToImport, setSkillToImport] = useState<CompanySkillListItemResponse>();
  const [importedSkills, setImportedSkills] = useState<CompanySkillListItemResponse[]>([]);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const response = await actions.getCompanySkills(request);
      setData(response);
      setLoading(false);
    }

    getData();
  }, [request]);

  return (
    <React.Fragment>
      <div className="panelContent">
        <FocusPanelHeader supertitle={supertitle} title="Import skills" subtitle="Search for global skills and add them to your company skills" divider />

        <div className="card" style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 15 }}>
          <SearchList
            loading={loading}
            onInput={search => setRequest(currentState => ({ ...currentState, search, page: 1 }))}
            paging={{
              activePageNumber: request.page ?? 1,
              pageCount: data?.totalPages ?? 0,
              onPageChange: page => setRequest(currentState => ({ ...currentState, page })),
            }}
          >
            {data?.entities.map(entity => {
              const selected = importedSkills.some(importedSkill => importedSkill.slug === entity.slug);
              return (
                <ListItem
                  key={entity.slug}
                  selected={selected}
                  onClick={() => {
                    if (selected) return;
                    setSkillToImport(entity);
                  }}
                >
                  <Fragment>
                    <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                      {entity.name}
                    </div>

                    <div className="caption2">{entity.description ?? 'No description provided'}</div>
                  </Fragment>
                </ListItem>
              );
            })}
          </SearchList>
        </div>

        <footer className="panelFooter">
          <Anchor onClick={onClose}>Back</Anchor>

          <Button type="button" disabled={!importedSkills.length} onClick={onClose}>
            Done
          </Button>
        </footer>
      </div>

      <Modal open={!!skillToImport} onClose={() => setSkillToImport(undefined)}>
        {!!skillToImport && (
          <ConfirmationModalLayout
            title="Confirm import"
            subtitle={`Are you sure you want to add ${skillToImport.name} to your company skills?`}
            submitButtonText="Import"
            onClickSubmit={async () => {
              setLoading(true);
              setSkillToImport(undefined);

              const response = await actions.importCompanySkill({
                companySlug: companyVariables.slug!,
                entitySlug: skillToImport.slug!,
              });

              if (!!response.error) {
                // TODO: use updated IError model
                actions.addNotification({
                  active: true,
                  id: crypto.randomUUID(),
                  type: NotificationType.Error,
                  title: 'Failed import!',
                  message: `There was an error adding ${skillToImport.name} to your company skills...`,
                  priority: NotificationPriority.Toast,
                });
                setLoading(false);
                return;
              }

              actions.addNotification({
                active: true,
                id: crypto.randomUUID(),
                type: NotificationType.Success,
                title: 'Successful import!',
                message: `You have successfully added ${skillToImport.name} to your company skills!`,
                priority: NotificationPriority.Toast,
              });
              setImportedSkills(currentState => [...currentState, skillToImport]);
              setLoading(false);
            }}
            cancelButtonText="Cancel"
            onClickCancel={() => setSkillToImport(undefined)}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}
