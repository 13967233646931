import React, { Fragment, useEffect, useReducer } from 'react';
import { CompletionStatus, FetchType } from '../../../enums';
import { getSunBurstData, sortByUrgencyIndication } from './skills-report.helpers';
import { OrganizationLevelType } from '../../../enums/organization-level';
import { AppPageWrapper } from '../../../design/layout.styles';
import { SkillsReportModalLayout } from './skills-report-modal/skills-report-modal.layout';
import { useAppActions, useAppState } from '../../../overmind';
import * as store from './skills-report.store';
import classNames from 'classnames';
import { SkillsReportSkeleton } from './skills-report.skeleton';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, HelpIcon, IconButton, Modal, PageHeader, Panel, SkillLevel, SkillPercentage, SunburstChart, TabSlide, TabSlider, colourString, useMatchScreenWidth } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { SkillScoresSearchParams } from '../../../models/overmind/search-params';
import { SkillScoresRequest, SkillScoresSkillSubTypeResponse } from '../../../models/overmind/analytics';
import { ResponseGroup } from '../../../enums/analytics';
import { AssessmentType } from '../../../enums/assessment-type';
import { UrgencyIndicationChip } from '../urgency-indication.chip';
import { SkillsReportSunburstSlice, SkillTypeName } from './skills-report.models';
import styles from './skills-report.module.css';
import { AnalyticsPageHeaderContainer } from '../../analytics/analytics.styles';
import { PDFReport } from '../../../components/general/pdf-report/pdf-report';
import { SkillLevelsExplainerPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/skill-levels-explainer-panel/skill-levels-explainer-panel.layout';
import { comparisonScoreTitle } from '../../../library/helpers/comparison-score-title';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { ScoreComparison } from '../../../enums/score-comparison';

export function SkillsReportPage(): JSX.Element {
  const actions = useAppActions();
  const { skillAssessmentConfig, user, companyVariables, dateRange } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const [open, setOpen] = React.useState(false);

  const isMobile = useMatchScreenWidth('mobile');

  const subdomain = getSubdomain();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.yourSkillsBase, type: FetchType.PageFetching });

      await actions.getForcedSkillAssessmentConfiguration();

      const searchParams: SkillScoresSearchParams = {
        startDate: undefined,
        endDate: undefined,
        organizationLevel: OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: user?.learnerSlug,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: false,
        scoreComparison: companyVariables.scoreComparison,
      };
      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.Skill,
        searchParams: searchParams,
      };
      const response = await actions.analyticsGetSkillScores(request);

      const skillSubTypes: SkillScoresSkillSubTypeResponse[] = [];
      if (!!response && !!response[0] && !!response[0].skillTypes) {
        response[0].skillTypes.forEach(skillType => {
          skillType.skillSubTypes?.forEach(skillSubType => {
            if (!skillSubType.skills?.length) return;
            if (!skillSubType.name) {
              switch (skillType.name) {
                case SkillTypeName.Behavioural:
                  skillSubType.name = 'Behavioral skills';
                  break;
                case SkillTypeName.Technical:
                  skillSubType.name = 'Technical skills';
                  break;
                case SkillTypeName.Combined:
                  skillSubType.name = 'Combined skills';
                  break;
                default:
                  skillSubType.name = 'Other skills';
                  break;
              }
            }
            skillSubTypes.push(skillSubType);
          });
        });
      }
      dispatch({ type: store.SkillsReportActionTypes.SetSkillTypes, payload: skillSubTypes });

      actions.stopLoader(PagePath.yourSkillsBase);
    }

    getData();
  }, [dateRange, companyVariables.slug]);

  useEffect(() => {
    if (!skillAssessmentConfig) return;
    dispatch({
      type: store.SkillsReportActionTypes.SetIncludeAwaitingAssessment,
      payload: !!skillAssessmentConfig.assessmentSlug && skillAssessmentConfig.assessmentResultReady && skillAssessmentConfig.assessmentType === AssessmentType.PeerEndorsement && skillAssessmentConfig.teamPeerReviewCompletionStatus !== CompletionStatus.Completed,
    });
  }, [skillAssessmentConfig]);

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.yourSkillsBase} skeletonLoader={<SkillsReportSkeleton />}>
        <div className={styles.skillsReportPageContainer}>
          <AppPageWrapper className="wrapper" style={{ pointerEvents: 'all' }}>
            <AnalyticsPageHeaderContainer>
              <PageHeader
                breadcrumbs={[
                  {
                    name: subdomain.includes('lpi') ? 'My Skills Report' : 'Your Skills',
                    url: PagePath.yourSkillsBase,
                  },
                  {
                    name: subdomain.includes('lpi') ? 'My Skills Report' : 'Skills Report',
                    url: PagePath.yourSkillsBase,
                  },
                ]}
                title={subdomain.includes('lpi') ? 'My Skills Report' : 'Skills Report'}
                type="actions"
              >
                <div className="headerActions">
                  <PDFReport path={PagePath.yourSkillsBase} />
                </div>
              </PageHeader>
            </AnalyticsPageHeaderContainer>

            {!!state.skillSubTypes.length || state.includeAwaitingAssessment ? (
              <TabSlider>
                {state.skillSubTypes.map((skillType, index, array) => {
                  const isFirst = index === 0;
                  const isLast = index === array.length - 1;
                  const sunBurstData: SkillsReportSunburstSlice[] = structuredClone(getSunBurstData(skillType.skills));
                  return (
                    <TabSlide name={skillType.name} key={skillType.slug}>
                      <div className={classNames(styles.skillsReportContentTabSlide, { isFirst, isLast })}>
                        <div className={classNames(styles.skillsReportCard, 'card')}>
                          {!isMobile && (
                            <div className={styles.skillsReportContentSunburst}>
                              <SunburstChart data={sunBurstData} scale={5} />
                            </div>
                          )}

                          <div className={styles.skillsReportContent}>
                            <div className={styles.skillsReportContentHeader}>
                              <div className={classNames('heading3', styles.skillsReportContentHeading)} id={skillType.slug}>
                                {skillType.name}
                                {!!companyVariables.useLevels && (
                                  <span>
                                    <IconButton iconType="fill" onClick={() => setOpen(true)}>
                                      <HelpIcon />
                                    </IconButton>
                                  </span>
                                )}
                              </div>

                              {!skillType.skills[0].urgencyIndication && (
                                <div className={styles.skillsReportContentScore}>
                                  <div className={styles.skillsReportContentLevel}>
                                    {companyVariables.useLevels ? (
                                      <SkillLevel level={skillType.score?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!skillType.score} title="Your proficiency" />
                                    ) : (
                                      <SkillPercentage percentage={skillType.score?.percentage ?? 0} noPercentage={!skillType.score} title="Your proficiency" />
                                    )}
                                  </div>

                                  {companyVariables.scoreComparison !== ScoreComparison.None && (
                                    <div className={styles.skillsReportContentLevel}>
                                      {companyVariables.useLevels ? (
                                        <SkillLevel
                                          level={skillType.comparisonScore?.level ?? companyVariables.minLevel}
                                          minLevel={companyVariables.minLevel}
                                          maxLevel={companyVariables.maxLevel}
                                          noLevel={!skillType.comparisonScore}
                                          noLevelText="Not captured"
                                          title={comparisonScoreTitle(companyVariables.scoreComparison)}
                                        />
                                      ) : (
                                        <SkillPercentage percentage={skillType.comparisonScore?.percentage ?? 0} noPercentage={!skillType.comparisonScore} noPercentageText="Not captured" title={comparisonScoreTitle(companyVariables.scoreComparison)} />
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            <div className={styles.skillsReportContentList}>
                              <h5 className="heading5">We've identified these areas for growth:</h5>
                              <div className={styles.skillItemList}>
                                <table className={styles.table}>
                                  <thead>
                                    <tr>
                                      <th className={styles.td} style={{ color: 'var(--accent-2)' }}>
                                        Skills name
                                      </th>
                                      {!!skillType.skills[0].urgencyIndication ? (
                                        <th className={styles.td} />
                                      ) : (
                                        <Fragment>
                                          <th className={styles.td}>Your proficiency</th>
                                          {companyVariables.scoreComparison !== ScoreComparison.None && <th className={styles.td}>{comparisonScoreTitle(companyVariables.scoreComparison)}</th>}
                                        </Fragment>
                                      )}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {sunBurstData
                                      .sort((a, b) => (!!a.urgencyIndication && !!b.urgencyIndication ? sortByUrgencyIndication(a.urgencyIndication, b.urgencyIndication) : a.value - b.value))
                                      .slice(0, 4)
                                      .map(slice => (
                                        <tr key={slice.name}>
                                          <td className={styles.td}>
                                            <div className={styles.listItem}>
                                              <span className={styles.listItemColor} style={{ background: colourString(slice.color) }} />
                                              <span className={styles.listItemName}>{slice.name}</span>
                                            </div>
                                          </td>

                                          {!!slice.urgencyIndication ? (
                                            <td className={styles.td} style={{ textAlign: 'end' }}>
                                              <UrgencyIndicationChip urgencyIndication={slice.urgencyIndication} />
                                            </td>
                                          ) : (
                                            <Fragment>
                                              <td className={styles.td}>
                                                {companyVariables.useLevels ? (
                                                  <SkillLevel level={slice.score?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!slice.score} />
                                                ) : (
                                                  <SkillPercentage percentage={slice.score?.percentage ?? 0} noPercentage={!slice.score} />
                                                )}
                                              </td>

                                              {companyVariables.scoreComparison !== ScoreComparison.None && (
                                                <td className={styles.td}>
                                                  {companyVariables.useLevels ? (
                                                    <SkillLevel level={slice.comparisonScore?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!slice.comparisonScore} noLevelText="Not captured" />
                                                  ) : (
                                                    <SkillPercentage percentage={slice.comparisonScore?.percentage ?? 0} noPercentage={!slice.comparisonScore} noPercentageText="Not captured" />
                                                  )}
                                                </td>
                                              )}
                                            </Fragment>
                                          )}
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className={styles.skillsReportContentAnchor}>
                              <Anchor block onClick={() => dispatch({ type: store.SkillsReportActionTypes.SetActiveSkillType, payload: skillType })}>
                                View Skills Breakdown
                              </Anchor>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabSlide>
                  );
                })}

                {state.includeAwaitingAssessment && (
                  <TabSlide name="Role-based skills">
                    <div className={classNames(styles.skillsReportRoleBasedTabSlide, 'isLast')}>
                      <div className={classNames(styles.skillsReportRoleBasedCard, 'card')}>
                        <h3 className="heading3">Team assessment in progress</h3>
                        <p className="body2">
                          <u>Some of your peers</u> are still busy with their assessments.
                        </p>
                        <p className="body2">Your results will be available once everyone has completed the process.</p>
                      </div>
                    </div>
                  </TabSlide>
                )}
              </TabSlider>
            ) : (
              <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} title="No assessments have been completed" subtitle="Please complete an assessment to see your skill score" />
            )}
          </AppPageWrapper>
        </div>
      </PageLoaderLayer>

      {!!state.activeSkillSubType && (
        <Modal open={!!state.activeSkillSubType} size="extralarge" onClose={() => dispatch({ type: store.SkillsReportActionTypes.SetActiveSkillType, payload: undefined })}>
          <SkillsReportModalLayout skillSubType={state.activeSkillSubType} />
        </Modal>
      )}

      {companyVariables.useLevels && (
        <Panel open={open} onClose={() => setOpen(false)}>
          <SkillLevelsExplainerPanelLayout onClose={() => setOpen(false)} />
        </Panel>
      )}
    </React.Fragment>
  );
}
