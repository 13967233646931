import React, { useState, useEffect } from 'react';
import { IWeglotLanguageSwitcherProps, LanguageCode, LanguageOptionType } from './weglot-switcher.models';
import { Button, Container, Option, List, ListHeading } from './weglot-switcher.styles';
import { FlagIcon } from '../../../design/icons/flag.icon';
import { ArrowIcon } from '@keplerco/core';
import CircleCheckIcon from '../../../design/icons/circle-check.icon';

const languageOptions: LanguageOptionType[] = [
  { code: 'zh', flag: <FlagIcon isoCode="CN" />, name: 'Chinese (Simplified)', abbreviation: 'ZHO' },
  { code: 'en', flag: <FlagIcon isoCode="GB" />, name: 'English', abbreviation: 'ENG' },
  { code: 'es', flag: <FlagIcon isoCode="ES" />, name: 'Spanish', abbreviation: 'SPA' },
  { code: 'fr', flag: <FlagIcon isoCode="FR" />, name: 'French', abbreviation: 'FRA' },
  { code: 'de', flag: <FlagIcon isoCode="DE" />, name: 'German', abbreviation: 'GER' },
  { code: 'ja', flag: <FlagIcon isoCode="JP" />, name: 'Japanese', abbreviation: 'JPN' },
  { code: 'ko', flag: <FlagIcon isoCode="KR" />, name: 'Korean', abbreviation: 'KOR' },
  { code: 'pt', flag: <FlagIcon isoCode="PT" />, name: 'Portuguese', abbreviation: 'POR' },
  { code: 'vi', flag: <FlagIcon isoCode="VN" />, name: 'Vietnamese', abbreviation: 'VIE' },
];

// TODO: refactor to use 'DropdownList' and be more consistent with other Dropdowns' UX
export default function WeglotLanguageSwitcher({ position = 'bottomLeft' }: IWeglotLanguageSwitcherProps) {
  const [currentLanguageCode, setCurrentLanguageCode] = useState<keyof LanguageCode>('en');
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if ((window as any).Weglot) {
      setCurrentLanguageCode((window as any).Weglot.getCurrentLang());
    }
  }, []);

  function onClickOption(lang: keyof LanguageCode) {
    if ((window as any).Weglot) {
      (window as any).Weglot.switchTo(lang);
      setCurrentLanguageCode(lang);
      setShowList(false);
    }
  }

  return (
    <Container position={position}>
      <Button onClick={() => setShowList(!showList)}>
        {languageOptions.find(option => option.code === currentLanguageCode)?.flag}
        {languageOptions.find(option => option.code === currentLanguageCode)?.abbreviation}
        <ArrowIcon tone="primary" size={15} rotation={showList ? 3 : 1} />
      </Button>

      {showList && (
        <List position={position}>
          <ListHeading className="heading5" style={{ fontSize: '0.78rem' }}>
            Language
          </ListHeading>

          {languageOptions.map(({ code, flag, name }: LanguageOptionType) => (
            <Option key={code} onClick={() => onClickOption(code)}>
              {flag} {name}
              {currentLanguageCode === code && <CircleCheckIcon />}
            </Option>
          ))}
        </List>
      )}
    </Container>
  );
}
