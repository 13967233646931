import React, { useEffect, useReducer, useState } from 'react';
import { FetchType, OrganizationLevelType } from '../../../enums';
import { ColumnConfiguratorWidget, ConfirmationModalLayout } from '../../../widgets/layouts';
import { IPeopleCMSLayoutProps } from './people.cms.models';
import { PeopleCMSSkeleton } from './people.cms.skeleton';
import { BaseSearchRequest, UniqueEntity } from '../../../models';
import { useAppActions, useAppState } from '../../../overmind';
import * as store from './people.cms.store';
import { ManagePersonCascadingPanels } from './panels/manage-person-panels/manage-person-cascading-panels';
import { PeopleHeaderActionsCMSLayout } from './people-header-actions.cms.layout';
import { PeopleTableCMSLayout } from './people-table.cms.layout';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { extractHighestOrganizationLevel } from '../../../library/helpers/permissions/extract-highest-organization-level';
import { CMSColumn, CMSRow } from '../../../design/layout.styles';
import { ITableDataRow, Modal, PageHeader, Panel, useMatchScreenWidth } from '@keplerco/core';

export function PeopleCMSLayout(props: IPeopleCMSLayoutProps): JSX.Element {
  const { user, permissions } = useAppState();
  const actions = useAppActions();

  const isMobile = useMatchScreenWidth('mobile');

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const [selectedRows, setSelectedRows] = useState<ITableDataRow[]>([]);
  const [loadingPeople, setLoadingPeople] = useState<string[]>([]);

  async function updateData(request: BaseSearchRequest) {
    // Fetch the data for the page from the api. If data
    // is not returned from the api, cancel the rest of
    // the fetch operation.
    const data = await actions.searchPeople(request);

    // Populate the data into the page state so that a
    // re-render can be triggered in the view
    dispatch({ type: store.PeopleCMSActionTypes.SetData, payload: { data } });

    actions.stopLoader(props.path);
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: props.path, type: FetchType.PageFetching });

      let entityName: string | undefined;

      switch (props.type) {
        case 'team': {
          const team = await actions.getTeam({ companySlug: props.slugs.companySlug!, teamSlug: props.slugs.teamSlug! });
          entityName = team?.teamName;
          break;
        }

        case 'department': {
          const department = await actions.getDepartment({ companySlug: props.slugs.companySlug!, departmentSlug: props.slugs.departmentSlug! });
          entityName = department?.name;
          break;
        }

        default: {
          if (user?.isSystemAdmin) {
            const company = await actions.getCompany(props.slugs.companySlug!);
            entityName = company?.companyName;
          } else entityName = user?.companyName;
          break;
        }
      }

      dispatch({ type: store.PeopleCMSActionTypes.SetHeader, payload: { ...props.slugs, entityName: entityName } });

      const organizationLevel = extractHighestOrganizationLevel(permissions?.administration.organizationLevels);
      if (user?.isSystemAdmin || organizationLevel?.organizationLevel === OrganizationLevelType.Company || (organizationLevel?.organizationLevel === OrganizationLevelType.Department && organizationLevel.entities.length > 1)) {
        const departments = await actions.getAdministrationDepartments(props.slugs.companySlug);
        dispatch({ type: store.PeopleCMSActionTypes.SetDepartments, payload: departments?.map(department => ({ slug: department.slug, name: department.name } as UniqueEntity)) });
      }

      if (user?.isSystemAdmin || organizationLevel?.organizationLevel === OrganizationLevelType.Company || organizationLevel?.organizationLevel === OrganizationLevelType.Department || (organizationLevel?.organizationLevel === OrganizationLevelType.Team && organizationLevel.entities.length > 1)) {
        const teams = await actions.getAdministrationTeams(props.slugs.companySlug);
        dispatch({ type: store.PeopleCMSActionTypes.SetTeams, payload: teams?.map(team => ({ slug: team.slug, name: team.teamName } as UniqueEntity)) });
      }

      const countries = await actions.getCountries();
      dispatch({ type: store.PeopleCMSActionTypes.SetCountries, payload: countries?.filter(country => country !== null).map(country => ({ ...country, slug: country.id?.toString() })) });

      // Constructs the request call and ensures that the
      // slugs for company, department and/or team are
      // populated in the request body
      const request: BaseSearchRequest = { ...state.request, ...props.slugs };
      dispatch({ type: store.PeopleCMSActionTypes.SetRequest, payload: request });

      await updateData(request);
    }

    initPage();
  }, [permissions]);

  useEffect(() => {
    if (!state.data) return;
    updateData(state.request);
  }, [state.request]);

  async function onClickSendActivationInvitationHandler(payload: { emails: string[]; shouldClearRows: boolean }) {
    if (!props.slugs.companySlug) return;

    actions.startLoader({ path: props.path, type: FetchType.Sending });

    await actions.sendActivationInvitations({
      companySlug: props.slugs.companySlug,
      emails: payload.emails,
    });

    if (payload.shouldClearRows) setSelectedRows([]);

    await updateData(state.request);
  }

  async function onClickSendActivationReminderHandler(payload: { emails: string[]; shouldClearRows: boolean }) {
    if (!props.slugs.companySlug) return;

    actions.startLoader({ path: props.path, type: FetchType.Sending });

    await actions.sendActivationReminders({
      companySlug: props.slugs.companySlug,
      emails: payload.emails,
    });

    if (payload.shouldClearRows) setSelectedRows([]);

    await updateData(state.request);
  }

  function onClickCancelManagingColumns() {
    dispatch({ type: store.PeopleCMSActionTypes.ManageColumns, payload: { open: false, reset: false } });
  }

  // modals
  function onClickCancelConfirmationModalHandler() {
    dispatch({ type: store.PeopleCMSActionTypes.SetPersonToArchive, payload: undefined });
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={props.path} skeletonLoader={<PeopleCMSSkeleton />}>
        <div className="wrapper stack">
          <CMSRow>
            <CMSColumn>
              <PageHeader breadcrumbs={state.crumbs} title={state.pageTitle} subtitle="Manage access, assign skills and tags, and move team members." />
            </CMSColumn>
          </CMSRow>

          <PeopleHeaderActionsCMSLayout
            path={props.path}
            isMobile={isMobile}
            request={state.request}
            setRequest={payload => dispatch({ type: store.PeopleCMSActionTypes.SetRequest, payload })}
            setPersonToManage={payload => dispatch({ type: store.PeopleCMSActionTypes.SetPersonToManage, payload })}
            selectedRows={selectedRows}
            setSelectedRows={async payload => {
              setSelectedRows(payload);
              await updateData(state.request);
              actions.stopLoader(props.path);
            }}
            data={state.data}
            columnConfiguration={state.columnConfiguration}
            departments={state.departments}
            teams={state.teams}
            countries={state.countries}
            onClickSendActivationInvitation={onClickSendActivationInvitationHandler}
            onClickSendActivationReminder={onClickSendActivationReminderHandler}
          />

          <CMSRow>
            <CMSColumn style={{ width: '100%' }}>
              <PeopleTableCMSLayout
                path={props.path}
                request={state.request}
                setRequest={payload => dispatch({ type: store.PeopleCMSActionTypes.SetRequest, payload })}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setManageColumns={payload => dispatch({ type: store.PeopleCMSActionTypes.ManageColumns, payload })}
                data={state.data}
                loadingPeople={loadingPeople}
                setLoadingPeople={async payload => {
                  await updateData(state.request);
                  setLoadingPeople(payload);
                }}
                setPersonToArchive={payload => dispatch({ type: store.PeopleCMSActionTypes.SetPersonToArchive, payload })}
                companySlug={props.slugs.companySlug}
                setPersonToManage={payload => dispatch({ type: store.PeopleCMSActionTypes.SetPersonToManage, payload })}
                columnConfiguration={state.columnConfiguration}
                onClickSendActivationInvitation={onClickSendActivationInvitationHandler}
                onClickSendActivationReminder={onClickSendActivationReminderHandler}
              />
            </CMSColumn>
          </CMSRow>
        </div>
      </PageLoaderLayer>

      {!!state.personToManage?.show && (
        <ManagePersonCascadingPanels
          personId={state.personToManage.data}
          onCancel={() => {
            dispatch({ type: store.PeopleCMSActionTypes.SetPersonToManage, payload: { show: false, data: void 0 } });
            updateData(state.request);
          }}
        />
      )}

      <Panel open={!!state.managingColumns} onClose={onClickCancelManagingColumns}>
        <ColumnConfiguratorWidget
          columns={state.columnConfiguration}
          onReset={() => dispatch({ type: store.PeopleCMSActionTypes.ManageColumns, payload: { open: true, reset: true } })}
          onCancel={onClickCancelManagingColumns}
          onSave={payload => {
            dispatch({ type: store.PeopleCMSActionTypes.EditColumnConfiguration, payload });
            dispatch({ type: store.PeopleCMSActionTypes.ManageColumns, payload: { open: false } });
          }}
        />
      </Panel>

      <Modal open={!!state.personToArchive} onClose={onClickCancelConfirmationModalHandler}>
        {!!state.personToArchive && (
          <ConfirmationModalLayout
            title="Archive person"
            titleSize="standard"
            subtitle={`Are you sure you want to archive ${state.personToArchive.firstName} ${state.personToArchive.lastName}?`}
            subtitleSize="standard"
            textAlign="left"
            submitButtonText="Yes, archive"
            onClickSubmit={async () => {
              if (!state.personToArchive?.id) return;

              const id = state.personToArchive?.id;
              dispatch({ type: store.PeopleCMSActionTypes.SetPersonToArchive, payload: undefined });

              setLoadingPeople(people => [...people, id]);
              await actions.archivePerson(id);
              await updateData(state.request);
              setLoadingPeople(people => [...people.filter(pid => id !== pid)]);
            }}
            cancelButtonText="No, cancel"
            onClickCancel={onClickCancelConfirmationModalHandler}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}
