import React from 'react';
import { Button, FocusPanelHeader, ListLayout, ListItemLayout } from '@keplerco/core';
import { getSkillLevelExplanations } from '../../../../library/helpers/get-skill-level-explanations';
import { useAppState } from '../../../../overmind';

export function SkillLevelsExplainerPanelLayout({ onClose }: { onClose: () => void }): JSX.Element {
  const { companyVariables, skillLevelConfig } = useAppState();
  const skillLevelExplanations = getSkillLevelExplanations(companyVariables.maxLevel); // TODO: remove explanations entirely

  return (
    <div className="panelContent">
      <FocusPanelHeader supertitle="Company skill levels" title="What does the skill level mean?" subtitle="Each level has set responsibilities that determine how effective you are at that skill." divider />

      {!!skillLevelConfig ? (
        <ListLayout>
          {skillLevelConfig.map(skillLevelExplanation => (
            <ListItemLayout key={skillLevelExplanation.level}>
              <div className="card">
                <h5 className="heading5">Level {skillLevelExplanation.level}</h5>
                <p className="body2">{skillLevelExplanation.description}</p>
              </div>
            </ListItemLayout>
          ))}
        </ListLayout>
      ) : (
        <ListLayout>
          {skillLevelExplanations.map(skillLevelExplanation => (
            <ListItemLayout key={skillLevelExplanation.title}>
              <div className="card">
                <h5 className="heading5">{skillLevelExplanation.title}</h5>
                <p className="body2">{skillLevelExplanation.description}</p>
              </div>
            </ListItemLayout>
          ))}
        </ListLayout>
      )}

      <footer className="panelFooter" style={{ justifyContent: 'flex-end' }}>
        <Button type="button" onClick={onClose}>
          Okay, Got It
        </Button>
      </footer>
    </div>
  );
}
