import { Anchor, Button, FocusPanelHeader, GridItemLayout, GridLayout, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { SearchList } from '../../../../../../components/lists/search-list';
import { ListItem } from '../../../../../../components/lists/list-item';
import { OrganizationLevelType, SortField } from '../../../../../../enums';
import classNames from 'classnames';
import styles from './select-cohort.module.css';
import { EmptyState } from '../../../../../../components/general/empty-state/empty-state';
import { Cohorts, CohortType, ISelectCohortLayoutProps } from '../people-step.models';
import { CompanyEntitySearchParams } from '../../../../../../models/overmind/search-params';
import { BaseSearchRequest } from '../../../../../../models';
import { DepartmentIcon } from '../../../../../../design/icons/department.icon';
import { PeopleIcon } from '../../../../../../design/icons/people.icon';
import { RoleIcon } from '../../../../../../design/icons/role.icon';
import { TeamIcon } from '../../../../../../design/icons/team-icon';
import { PagePath } from '../../../../../../navigation/navigation.enums';

export function SelectCohortLayout({ selectedCohort, type, setSelectedCohort, onBack, onNext }: ISelectCohortLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedCohortType, setSelectedCohortType] = useState<CohortType>(CohortType.Department);
  const [cohorts, setCohorts] = useState<Cohorts>();
  const [search, setSearch] = useState<string>('');
  const [activePageNumber, setActivePageNumber] = useState<number>(1);

  const isMobile = useMatchScreenWidth('mobile');

  async function getRoles() {
    const searchParams: CompanyEntitySearchParams = {
      search: search,
      page: activePageNumber,
      pageSize: 5,
      sortAscending: true,
      sortField: SortField.Name,
      departmentSlug: undefined,
      learnerSlug: undefined,
      teamSlug: undefined,
      companySlug: companyVariables.slug,
      organizationLevel: OrganizationLevelType.Company,
      searchGlobally: false,
    };
    const response = await actions.getCompanyRoles(searchParams);
    setCohorts({ entities: response?.entities.map(entity => ({ slug: entity.slug, name: entity.name, type: CohortType.Role })) ?? [], totalPages: response?.totalPages ?? 0, totalCount: response?.totalCount ?? 0 });
  }

  async function getDepartments() {
    const departmentRequest: BaseSearchRequest = { search: search, sortAscending: true, sortField: SortField.Name, pageSize: 15, companySlug: companyVariables.slug };
    const response = await actions.searchDepartments(departmentRequest);
    setCohorts({ entities: response?.departments.map(department => ({ slug: department.slug, name: department.name, type: CohortType.Department })) ?? [], totalPages: response?.totalPages ?? 0, totalCount: response?.totalCount ?? 0 });
  }

  async function getTeams() {
    const request: BaseSearchRequest = { search: search, sortAscending: true, sortField: SortField.Name, pageSize: 15, companySlug: companyVariables.slug };
    const response = await actions.searchTeams(request);
    setCohorts({ entities: response?.teams.map(team => ({ slug: team.teamSlug, name: team.teamName, type: CohortType.Role })) ?? [], totalPages: response?.totalPages ?? 0, totalCount: response?.totalPages ?? 0 });
  }

  useEffect(() => {
    async function getData() {
      setLoading(true);

      switch (selectedCohortType) {
        case CohortType.Department: {
          await getDepartments();
          break;
        }

        case CohortType.Team: {
          await getTeams();
          break;
        }

        case CohortType.Role: {
          await getRoles();
          break;
        }

        default:
          break;
      }
      setLoading(false);
    }

    getData();
  }, [search]);

  return (
    <div className="panelContent">
      {type === PagePath.assessmentManagementBase ? (
        <FocusPanelHeader supertitle="Assessments" title="Who do you want to assess?" subtitle="Select departments, teams, role or individual people to take part in the assessment." />
      ) : (
        <FocusPanelHeader supertitle="Roles" title="Who do you want to assign?" subtitle="Select the people that you want to assign to this role." />
      )}

      <div>
        <div className={classNames('card', styles.content)}>
          <GridLayout columnCount={isMobile ? 1 : type === PagePath.assessmentManagementBase ? 4 : 3}>
            <GridItemLayout>
              <div
                className={classNames(styles.cohort, {
                  [styles.selected]: selectedCohortType === CohortType.Department,
                })}
                onClick={() => {
                  setSelectedCohortType(CohortType.Department);
                  getDepartments();
                }}
              >
                <DepartmentIcon />
                <div>
                  <div className={classNames('heading5')}>Department</div>

                  <div className={classNames('caption2')}>Search within a department</div>
                </div>
              </div>
            </GridItemLayout>

            <GridItemLayout>
              <div
                className={classNames(styles.cohort, {
                  [styles.selected]: selectedCohortType === CohortType.Team,
                })}
                onClick={() => {
                  setSelectedCohortType(CohortType.Team);
                  getTeams();
                }}
              >
                <TeamIcon />
                <div>
                  <div className={classNames('heading5')}>Team</div>

                  <div className={classNames('caption2')}>Search within a team</div>
                </div>
              </div>
            </GridItemLayout>

            {type === PagePath.assessmentManagementBase && (
              <GridItemLayout>
                <div
                  className={classNames(styles.cohort, {
                    [styles.selected]: selectedCohortType === CohortType.Role,
                  })}
                  onClick={() => {
                    setSelectedCohortType(CohortType.Role);
                    getRoles();
                  }}
                >
                  <RoleIcon />
                  <div>
                    <div className={classNames('heading5')}>Role</div>

                    <div className={classNames('caption2')}>Choose a role in the company</div>
                  </div>
                </div>
              </GridItemLayout>
            )}

            <GridItemLayout>
              <div
                className={classNames(styles.cohort, {
                  [styles.selected]: selectedCohortType === CohortType.People,
                })}
                onClick={() => {
                  setSelectedCohortType(CohortType.People);
                  setSelectedCohort({ name: undefined, slug: undefined, type: CohortType.People });
                  setCohorts({ entities: [], totalPages: 0, totalCount: 0 });
                  onNext();
                }}
              >
                <PeopleIcon />
                <div>
                  <div className={classNames('heading5')}>People</div>

                  <div className={classNames('caption2')}>Search all people</div>
                </div>
              </div>
            </GridItemLayout>
          </GridLayout>
          {loading && !cohorts ? (
            <div className={styles.loadingState}>
              <object data={'/graphics/loading-state.graphic.svg'} type="image/svg+xml">
                <img src={'graphics/loading-state.graphic.svg'} alt="" />
              </object>

              <span className="heading5">Loading...</span>
            </div>
          ) : (
            <>
              {selectedCohortType !== CohortType.People && (
                <SearchList
                  loading={loading}
                  onInput={search => setSearch(search)}
                  paging={{
                    activePageNumber: activePageNumber ?? 1,
                    pageCount: cohorts?.totalPages ?? 0,
                    onPageChange: page => setActivePageNumber(page),
                  }}
                >
                  {!cohorts?.entities.length ? (
                    <EmptyState title="Nothing to show" subtitle="Select department, team, role or prople" />
                  ) : (
                    cohorts?.entities.map(cohort => {
                      return (
                        <ListItem key={cohort.slug} selected={selectedCohort?.slug === cohort.slug} onClick={() => setSelectedCohort({ slug: cohort.slug, name: cohort.name, type: selectedCohortType })}>
                          <h5 className="caption1" style={{ color: 'var(--accent-2)' }}>
                            {cohort.name}
                          </h5>
                        </ListItem>
                      );
                    })
                  )}
                </SearchList>
              )}
            </>
          )}
        </div>
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={() => onNext()}>
          Next
        </Button>
      </footer>
    </div>
  );
}
