import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Department, Team } from '../../../../models';
import { useAppActions } from '../../../../overmind';
import { useParams } from 'react-router-dom';
import { Button, DropdownMenu, DropdownSearch, DropdownSelect, EmailValidator, FieldController, FocusPanelHeader, LettersValidator, RequiredValidator, TextField } from '@keplerco/core';
import { UserBulkUploadRow } from '../../../../models/bulk-uploads/bulk-upload-dto';
import { IBulkUploadEditFocusPanelProps } from './bulk-upload.models';
import { LANGUAGES } from '../../../../library/consts/languages';
import { FlagIcon } from '../../../../design/icons/flag.icon';
import { Country } from '../../../onboarding/onboarding.models';

enum FieldName {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Email = 'Email',
  JobTitle = 'JobTitle',
  Department = 'Department',
  Team = 'Team',
  Country = 'Country',
  Languages = 'Languages',
}

type FormValues = Omit<UserBulkUploadRow, 'rowNumber' | 'isValid' | 'validationMessages'>;

export default function BulkUploadEditFocusPanel(props: IBulkUploadEditFocusPanelProps) {
  const defaultValues = {
    firstName: props.selectedRow?.firstName ?? '',
    lastName: props.selectedRow?.lastName ?? '',
    email: props.selectedRow?.email ?? '',
    jobTitle: props.selectedRow?.jobTitle ?? '',
    department: props.selectedRow?.department ?? '',
    team: props.selectedRow?.team ?? '',
    country: props.selectedRow?.country ?? '',
    languages: props.selectedRow?.languages
      ? (Array.isArray(props.selectedRow.languages)
        ? props.selectedRow.languages
        : (props.selectedRow.languages as string).split(',').map(lang => lang.trim()))
      : [],

  };

  const { control, setValue, trigger, watch, getValues } = useForm<any>({
    defaultValues,
    mode: 'onChange',
  });

  const actions = useAppActions();
  const params = useParams();

  const [departments, setDepartments] = useState<Department[]>();
  const [countries, setCountries] = useState<Country[]>();
  const [teams, setTeams] = useState<Team[]>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);

  useEffect(() => {
    watch(() => {
      setIsDirty(true);
      const values = getValues();
      setFormValues(values);
    });
  }, []);

  useEffect(() => {
    if (props.selectedRow) {
      setValue('firstName', props.selectedRow.firstName);
      setValue('lastName', props.selectedRow.lastName);
      setValue('email', props.selectedRow.email);
      setValue('jobTitle', props.selectedRow.jobTitle);
      setValue('department', props.selectedRow.department);
      setValue('team', props.selectedRow.team);
      setValue('languages', props.selectedRow.languages);
      setValue('country', props.selectedRow.country);
      trigger([FieldName.FirstName, FieldName.LastName, FieldName.Email, FieldName.JobTitle, FieldName.Department, FieldName.Team, FieldName.Country, FieldName.Languages]);
    }
  }, [props.selectedRow, setValue, trigger]);

  useEffect(() => {
    actions.getAdministrationTeams(params.companySlug!).then(fetchedTeams => {
      setTeams(fetchedTeams);
    });

    actions.getAdministrationDepartments(params.companySlug!).then(fetchedDepartments => {
      setDepartments(fetchedDepartments);
    });

    actions.getCountries().then(fetchedCountries => {
      setCountries(fetchedCountries);
    });
  }, [params.companySlug, props.selectedRow?.team, props.selectedRow?.department, props.selectedRow?.country, actions]);

  function handleSubmit() {
    if (!!formValues && !!props.selectedRow) {
      const currentLanguages = getValues('languages');

      const languagesArray = typeof currentLanguages === 'string'
        ? currentLanguages.split(',').map((lang: string) => lang.trim())
        : Array.isArray(currentLanguages)
          ? currentLanguages
          : [];


      const row: UserBulkUploadRow = {
        ...formValues,
        languages: languagesArray, 
        rowNumber: props.selectedRow.rowNumber,
        isValid: props.selectedRow.isValid,
        validationMessages: props.selectedRow.validationMessages,
      };


      props.onSubmit(row);
    }
  }

  function getFieldError(fieldName: string): string | undefined {
    const error = props.selectedRow?.validationMessages?.find(err => err.fieldName === fieldName);
    return error ? error.message : undefined;
  }

  function clearApiError(fieldName: string) {
    if (props.selectedRow) {
      const updatedValidationMessages = props.selectedRow.validationMessages?.filter(err => err.fieldName !== fieldName);

      props.selectedRow.validationMessages = updatedValidationMessages;
      trigger(fieldName);
    }
  }

  return (
    <div className="panelContent">
      <FocusPanelHeader title={`Manage ${props.selectedRow?.firstName} ${props.selectedRow?.lastName}`} divider />

      <form id="editBulkUpload">
        <div className="row">
          <div className="column">
            <FieldController
              control={control}
              rules={new LettersValidator('Enter a first name')}
              name="firstName"
              render={({ field, fieldState }) => {
                const fieldError = getFieldError(FieldName.FirstName);
                return (
                  <TextField
                    {...field}
                    type="text"
                    label="First name"
                    onBlur={() => clearApiError(FieldName.FirstName)}
                    onChange={e => {
                      field.onChange(e);
                      clearApiError(FieldName.FirstName);
                    }}
                    responsive
                    validation={{ dirty: !!fieldError || fieldState.isDirty, invalid: !!fieldError || !!fieldState.error, message: fieldError ?? fieldState.error?.message ?? 'Enter a first name' }}
                  />
                );
              }}
            />
          </div>

          <div className="column">
            <FieldController
              control={control}
              rules={new LettersValidator('Enter a last name')}
              name="lastName"
              render={({ field, fieldState }) => {
                const fieldError = getFieldError(FieldName.LastName);
                return (
                  <TextField
                    {...field}
                    type="text"
                    onBlur={() => clearApiError(FieldName.LastName)}
                    onChange={e => {
                      field.onChange(e);
                      clearApiError(FieldName.LastName);
                    }}
                    label="Last name"
                    responsive
                    validation={{ dirty: !!fieldError || fieldState.isDirty, invalid: !!fieldError || !!fieldState.error, message: fieldError ?? fieldState.error?.message ?? 'Enter a last name' }}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <FieldController
              control={control}
              rules={new EmailValidator()}
              name="email"
              render={({ field, fieldState }) => {
                const fieldError = getFieldError(FieldName.Email);
                return (
                  <TextField
                    {...field}
                    type="email"
                    onBlur={() => clearApiError(FieldName.Email)}
                    onChange={e => {
                      field.onChange(e);
                      clearApiError(FieldName.Email);
                    }}
                    label="Email"
                    responsive
                    validation={{ dirty: !!fieldError || fieldState.isDirty, invalid: !!fieldError || !!fieldState.error, message: fieldError ?? fieldState.error?.message ?? 'Enter a first name' }}
                  />
                );
              }}
            />
          </div>

          <div className="column">
            <FieldController
              control={control}
              rules={new RequiredValidator('Enter a job title')}
              name="jobTitle"
              render={({ field, fieldState }) => {
                const fieldError = getFieldError(FieldName.JobTitle);
                return (
                  <TextField
                    {...field}
                    type="text"
                    label="Job title"
                    onBlur={() => clearApiError(FieldName.JobTitle)}
                    onChange={e => {
                      field.onChange(e);
                      clearApiError(FieldName.JobTitle);
                    }}
                    responsive
                    validation={{ dirty: !!fieldError || fieldState.isDirty, invalid: !!fieldError || !!fieldState.error, message: fieldError ?? fieldState.error?.message ?? 'Enter a job title' }}
                  />
                );
              }}
            />
          </div>
        </div>

        {!!countries && (
          <div className="row">
            <div className="column">
              <DropdownSearch
                label="Country"
                defaultValue={formValues.country}
                items={countries.map(country => ({
                  value: country.id!.toString(),
                  label: country.name,
                  icon: <FlagIcon isoCode={country.iso} />,
                  onClick: () => {
                    setValue('country', country.name);
                    setIsDirty(true);
                    clearApiError(FieldName.Country);
                  },
                  selected: formValues.country === country.name,
                }))}
                value={countries.find(country => country.name === formValues.country)?.id?.toString()}
                validation={{
                  dirty: isDirty,
                  invalid: !formValues.country || !!getFieldError(FieldName.Country),
                  message: getFieldError(FieldName.Country) ?? 'Please select a country',
                }}
                onBlur={() => {
                  if (!formValues.country) {
                    setIsDirty(true);
                  }
                  clearApiError(FieldName.Country);
                }}
                responsive
                dialogContained
              />
            </div>
          </div>
        )}

        {!!departments && (
          <FieldController
            control={control}
            name="department"
            rules={new RequiredValidator('Choose a department')}
            render={({ field, fieldState }) => {
              const fieldError = getFieldError(FieldName.Department);
              return (
                <DropdownSelect
                  {...field}
                  label="Department"
                  items={departments
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(department => ({
                      value: department.name,
                      onClick: () => {
                        setValue('department', department.name);
                        setIsDirty(true);
                        clearApiError(FieldName.Department);
                      },
                    }))}
                  value={formValues.department}
                  validation={{ dirty: !!fieldError || fieldState.isDirty, invalid: !!fieldError || !!fieldState.error, message: fieldError ?? fieldState.error?.message ?? 'Choose a department' }}
                  responsive
                />
              );
            }}
          />
        )}

        {!!teams && (
          <FieldController
            control={control}
            name="team"
            rules={new RequiredValidator('Choose a team')}
            render={({ field, fieldState }) => {
              const fieldError = getFieldError(FieldName.Team);
              return (
                <DropdownSelect
                  {...field}
                  label="Team"
                  items={teams
                    .filter(team => (!formValues.department ? true : team.departmentSlug === departments?.find(dep => dep.name === formValues.department)?.slug))
                    .sort((a, b) => a.teamName.localeCompare(b.teamName))
                    .map(team => ({
                      value: team.teamName,
                      onClick: () => {
                        setValue('team', team.teamName);
                        setIsDirty(true);
                        clearApiError(FieldName.Team);
                      },
                    }))}
                  value={formValues.team}
                  validation={{ dirty: !!fieldError || fieldState.isDirty, invalid: !!fieldError || !!fieldState.error, message: fieldError ?? fieldState.error?.message ?? 'Choose a team' }}
                  responsive
                />
              );
            }}
          />
        )}

        <FieldController
          control={control}
          name="languages"
          render={({ field }) => {
            const selectedLanguages = field.value
              ? (Array.isArray(field.value)
                ? field.value
                : field.value.split(',').map((lang: string) => lang.trim()))
              : [];

            return (
              <DropdownMenu
                {...field}
                multiple
                label="Languages"
                items={LANGUAGES.map((language) => ({
                  value: language,
                  onClick: () => {
                    const isSelected = selectedLanguages.includes(language);
                    const updatedLanguages = isSelected
                      ? selectedLanguages.filter((lang: string) => lang !== language)
                      : [...selectedLanguages, language];


                    field.onChange(updatedLanguages);
                    setValue('languages', updatedLanguages);
                    setIsDirty(true);
                  },
                  selected: selectedLanguages.includes(language),
                }))}
                responsive
              />
            );
          }}
        />
      </form>

      <div className="panelFooter" style={{ display: 'flex', justifyContent: 'end' }}>
        <Button type="button" filled onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </div>
  );
}
