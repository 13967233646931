import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 42px;
  padding: 2px 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px;
  background-color: var(--cards);
  border: 1.5px solid var(--borders);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.78rem;
  cursor: pointer;
  color: var(--text);
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  font-size: 0.78rem;
`;

export const Container = styled.div<{ position: string }>`
  display: inline-block;
  z-index: 9999;

  ${props =>
    props.position === 'bottomLeft' &&
    `
    position: fixed;
    bottom: 10px;
    left: 40px;
  `}

  ${props =>
    props.position === 'topNavigation' &&
    `
    position: relative; 
    padding-right: 10px;
  `}
`;

export const List = styled.div<{ position: string }>`
  position: absolute;
  background-color: var(--cards);
  color: var(--text);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--borders);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  margin-bottom: 10px;
  right: 0px;

  overflow-y: auto;
  max-height: 50vh;
  min-width: 200px;

  @media (max-width: 600px) {
    max-height: 70vh;
  }

  ${props =>
    props.position === 'bottomLeft' &&
    `
    bottom: 100%;
  `}

  ${props =>
    props.position === 'topNavigation' &&
    `
    top: 100%;
    margin-top: 10px;
  `}
`;

export const ListHeading = styled.h5`
  font-weight: 400;
`;
